










































































































































































































































































































































import Vue from "vue";
import { Component } from "vue-property-decorator";
import Artist from "@/components/Artist.vue";

@Component({
  name: 'artists',
  components: { Artist }
})
export default class Artists extends Vue {

}

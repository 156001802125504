




















import Vue from 'vue';
import { Component } from "vue-property-decorator";
import MenuBar from "@/components/MenuBar.vue";

@Component({
  name: 'App',
  components: { MenuBar }
})
export default class App extends Vue {

}

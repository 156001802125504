import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import CustomDeezer from "@/components/icons/CustomDeezer.vue";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    values: {
      deezer: { // name of our custom icon
        component: CustomDeezer, // our custom component
      },
    },
  },
});

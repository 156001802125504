import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from "@/views/Home/Home.vue";
import Artists from "@/views/Artists/Artists.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/artists',
    name: 'artists',
    component: Artists
  }
];

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  }
});

export default router;

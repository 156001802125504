














import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component({
  name: 'artist'
})
export default class Artist extends Vue {
  @Prop({ type: Boolean, default: false }) reversed!: boolean;
  @Prop({ type: String, required: true }) imgSrc!: string;
  @Prop({ type: String, required: true }) name!: string;
  @Prop({ type: String, required: true }) description!: string;
}









































































































































































import Vue from "vue";
import { Component } from "vue-property-decorator";

@Component({
  name: 'home'
})
export default class Home extends Vue {

}

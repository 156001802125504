




































































import Vue from 'vue';
import { Component } from "vue-property-decorator";
import { GoToOptions } from "vuetify/types/services/goto";

@Component
export default class MenuBar extends Vue {

  get target() {
    return (selector: string | number) => selector;
  }

  get options(): GoToOptions {
    return {
      duration: 500,
      offset: 0,
      easing: 'easeInQuad'
    };
  }

  onClickIcon() {
    this.$router.push('/');
    window.scrollTo(0,0);
  }
}

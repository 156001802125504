































































































































import Vue from "vue";
import { Component } from "vue-property-decorator";

@Component({
  name: 'customdeezer'
})
export default class CustomDeezer extends Vue {
}
